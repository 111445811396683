<template>
  <div class="section-full-width section-130 section-with-decoration dark-bg">
    <span class="decoration decoration_1"></span>
    <span class="decoration decoration_2"></span>
    <span class="decoration decoration_3"></span>
    <div class="container">
      <router-view />
    </div>
    <div class="footer-auth">
      <div class="container">
        <div class="footer-auth__wrapper">
          <router-link :to="$localePath({ name: 'Home' })" class="footer__logo">
            <img :src="require('@/assets/image/logo-white.svg')" width="120" alt="logo" />
          </router-link>
          <p class="text-white text-md text-opacity-06">© {{ getYear }} All rights reserved.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthLayout",
  computed: {
    getYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped lang="scss">
.footer-auth {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 30px;
}
.footer-auth__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
