const ru = {
  btn: {
    registration: "Регистрация",
    openAccount: "Открыть аккаунт",
    join: "Присоедениться",
    login: "Войти",
    register: "Зарегистрироваться",
    continue: "Продолжить",
    begin: "Начать",
    createAccount: "Создать аккаунт",
  },
  menu: {
    home: "Главная",
    markets: "Рынки",
    about: "О нас",
    contacts: "Контакты",
    platform: "Платформа",
    faq: "FAQ",
    trade: "Торговля",
    strategies: "Стратегии",
    tools: "Инструменты",
  },
  subMenu: {
    crypto: "Основы криптовалют",
    security: "Безопасность и защита",
  },
  form: {
    name: "Имя",
    phone: "Номер телефона",
    mail: "Ваша эл.почта",
    lastName: "Фамилия",
    password: "Пароль",
  },
  privacy: "Политика конфиденциальности",
  terms: "Политика конфиденциальности",
  assets: "Активы",
  support: "Техподдержка",
  leverageTo: "Плечо до",
  depositFrom: "Депозит от",
  cities: ["Великобритания", "Кипр", "Гонконг"],
  popularAssets: "Популярные инструменты",
  worksDay1: "Пн-Пт",
  worksDay2: "Сб-Вс",
  mail: "Почта",
  works: "Часы работы",
  ourAddress: "Наш адрес",
  client: "Клиентское соглашение",
  legalInfo: "Клиентское соглашение",
};

export default ru;
