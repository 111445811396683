<template>
  <div>
    <AppHeader />
    <RouterView />
    <AppFooter />
  </div>
</template>

<script>
import AppFooter from "@/components/app/AppFooter.vue";
import AppHeader from "@/components/app/AppHeader.vue";
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  name: "BaseLayout",
  components: { AppHeader, AppFooter },
  mounted() {
    AOS.init({
      disable: function () {
        var maxWidth = 1200;
        return window.innerWidth < maxWidth;
      },
      duration: 700,
    });
  },
};
</script>

<style scoped></style>
