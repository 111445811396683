<script>
import LanguageSwitcher from "@/components/app/LanguageSwitcher.vue";

export default {
  name: "AppHeader",
  components: { LanguageSwitcher },
  data: function () {
    return {
      isMobileOpen: false,
      isSubmenuOpen: false,
    };
  },
  mounted() {
    document.addEventListener("click", this.clickHandler);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.clickHandler);
  },
  watch: {
    $route() {
      this.isMobileOpen = false;
      this.isSubmenuOpen = false;
    },
  },
  methods: {
    toggleMobile() {
      this.isMobileOpen = !this.isMobileOpen;
    },
    toggleSubmenu() {
      this.isSubmenuOpen = !this.isSubmenuOpen;
    },
    hideMobile() {
      this.isMobileOpen = false;
    },
    clickHandler(e) {
      if (!this.$el.contains(e.target)) {
        this.isSubmenuOpen = false;
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="navbar-layout" :class="{ 'open-mobile': isMobileOpen }" @click="hideMobile"></div>
    <header class="header" :class="{ 'open-mobile': isMobileOpen }">
      <div class="container">
        <div class="header__inner">
          <router-link :to="$localePath({ name: 'Home' })">
            <img :src="require('@/assets/image/logo-white.svg')" width="150" alt="" />
          </router-link>
          <div class="menu-wrapper">
            <ul class="header__nav">
              <li>
                <router-link
                  :to="$localePath({ name: 'Market' })"
                  class="nav-link"
                  active-class="active"
                >
                  {{ $t("menu.markets") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="$localePath({ name: 'Platform' })"
                  class="nav-link"
                  active-class="active"
                >
                  {{ $t("menu.platform") }}
                </router-link>
              </li>
              <li class="has-submenu" :class="{ submenu_open: isSubmenuOpen }">
                <div class="submenu-link">
                  <router-link
                    :to="$localePath({ name: 'Trade' })"
                    class="nav-link"
                    active-class="active"
                  >
                    {{ $t("menu.trade") }}
                  </router-link>
                  <div class="submenu-btn" @click="toggleSubmenu()">
                    <span class="ic-arrow-down"></span>
                  </div>
                </div>

                <ul class="submenu">
                  <li>
                    <router-link :to="$localePath({ name: 'Strategies' })" class="submenu__item">
                      {{ $t("menu.strategies") }}
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="$localePath({ name: 'Tools' })" class="submenu__item">
                      {{ $t("menu.tools") }}
                    </router-link>
                  </li>
                </ul>
              </li>
              <li>
                <router-link
                  :to="$localePath({ name: 'About' })"
                  class="nav-link"
                  active-class="active"
                >
                  {{ $t("menu.about") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="$localePath({ name: 'Contacts' })"
                  class="nav-link"
                  active-class="active"
                >
                  {{ $t("menu.contacts") }}
                </router-link>
              </li>
              <li class="d-lg-none">
                <router-link :to="$localePath({ name: 'Login' })" class="btn btn-red btn-sm">
                  {{ $t("btn.login") }}
                  <span class="ic-login btn-icon"></span>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="header__right">
            <LanguageSwitcher />
            <div class="d-none d-lg-block">
              <router-link :to="$localePath({ name: 'Login' })" class="btn btn-red btn-sm">
                {{ $t("btn.login") }}
                <span class="ic-login btn-icon"></span>
              </router-link>
            </div>

            <div class="hamburger-lines" @click="toggleMobile">
              <span class="line line1"></span>
              <span class="line line2"></span>
              <span class="line line3"></span>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<style scoped lang="scss">
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  box-shadow: 0px 22px 12.166px -10px rgba(0, 0, 0, 0.1);
  background: rgba($black, 0.72);
  backdrop-filter: blur(100px);
}
.header__inner {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  min-height: 58px;
}
.header__nav {
  display: flex;
  align-items: center;
  gap: 30px;
  font-size: 0;
}

.header__right {
  display: flex;
  align-items: center;
  gap: 12px;
}

.nav-link {
  position: relative;
  display: inline-block;
  color: rgba($white, 0.6);
  overflow: hidden;
  padding: 3px 0;
  font-size: 16px;

  &:before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 100%;
    background: $red;
    border-radius: 2px;
    height: 2px;
    transition: $transition;
    transform: translateX(-110%);
  }

  &:hover,
  &.active {
    color: $white;

    &:before {
      transform: translateX(0);
    }
  }
}
.submenu-link {
  display: flex;
  align-items: center;
  gap: 10px;
}
.has-submenu {
  position: relative;
}
.submenu-btn {
  display: block;
  font-size: 10px;
  line-height: 1;
  color: rgba($white, 0.6);
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    color: $white;
  }
}
.submenu {
  display: none;
  min-width: 260px;
  position: absolute;
  padding: 6px 30px;
  background: rgba($black, 0.72);
  backdrop-filter: blur(100px);
  top: calc(100% + 15px);
  left: -30px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  transition: all ease-in-out 0.3s;
  border-radius: 0 0 10px 10px;

  li {
    padding: 10px 0;
    border-bottom: 1px solid rgba($white, 0.2);

    &:last-child {
      border-bottom: none;
    }
  }

  .submenu__item {
    color: $white;
    font-size: 14px;
    line-height: 20px;

    &:hover {
      color: $white;
    }
  }
}
.submenu_open {
  .nav-link {
    color: $white;
  }

  .submenu-btn {
    color: $white;
    transform: rotate(180deg);
  }

  .submenu {
    display: block;
  }
}
.navbar-layout {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: rgba($dark, 0.2);

  &.open-mobile {
    display: block;
  }
}

.hamburger-lines {
  position: relative;
  display: none;
  height: 24px;
  width: 32px;
  cursor: pointer;
}

.hamburger-lines .line {
  position: absolute;
  display: block;
  height: 2px;
  width: 100%;
  background: $white;
  left: 0;
}

.hamburger-lines .line1 {
  top: 0;
  transform-origin: 0% 0%;
  transition: transform 0.3s ease-in-out;
}

.hamburger-lines .line2 {
  top: calc(12px - 1px);
  transition: transform 0.1s ease-in-out;
}

.hamburger-lines .line3 {
  bottom: 0;
  transform-origin: 0% 100%;
  transition: transform 0.3s ease-in-out;
}

.open-mobile .hamburger-lines .line1 {
  transform: rotate(45deg);
}

.open-mobile .hamburger-lines .line2 {
  transform: scaleY(0);
}

.open-mobile .hamburger-lines .line3 {
  transform: rotate(-45deg);
}

@include media-breakpoint-down(lg) {
  .hamburger-lines {
    display: block;
  }

  .submenu-link {
    justify-content: center;
  }

  .submenu {
    position: relative;
    background: transparent;
    min-width: inherit;
    text-align: center;
    padding: 10px 0;
    top: inherit;
    left: inherit;
    backdrop-filter: inherit;
  }

  .menu-wrapper {
    position: fixed;
    top: 58px;
    right: 0;
    height: calc(100vh - 58px);
    width: 300px;
    overflow-y: auto;
    padding: 30px 20px;
    background: $dark;
    transition: all ease-in-out 0.3s;
    transform: translateX(110%);
    background: rgba($black, 0.92);
    backdrop-filter: blur(100px);

    display: flex;
    align-items: center;
    justify-content: center;

    .header__nav {
      flex-direction: column;
      align-items: start;
      text-align: center;
      gap: 30px;

      li {
        width: 100%;
      }

      .nav-link {
        font-size: 20px;
      }

      .btn {
        width: 100%;
      }
    }
  }

  .open-mobile {
    .menu-wrapper {
      transform: translateX(0);
    }
  }
}
</style>
