const fr = {
  btn: {
    registration: "Inscription",
    openAccount: "Compte ouvert",
    join: "Rejoindre",
    login: "Entrer",
    register: "Enregistrer",
    continue: "Continuer",
    begin: "Commencer",
    createAccount: "Créer un compte",
  },
  menu: {
    home: "Maison",
    markets: "Marchés",
    about: "À propos de nous",
    contacts: "Contacts",
    platform: "Plateforme",
    faq: "FAQ",
    trade: "Commerce",
    strategies: "Stratégies",
    tools: "Instruments",
  },
  subMenu: {
    crypto: "Bases de la crypto-monnaie",
    security: "Sécurité et protection",
  },
  form: {
    name: "Nom",
    phone: "Numéro de téléphone",
    mail: "Votre email",
    lastName: "Nom de famille",
    password: "Mot de passe",
  },
  privacy: "Politique de confidentialité",
  assets: "Actifs",
  support: "Soutien",
  leverageTo: "Épaule levée",
  depositFrom: "Dépôt de",
  cities: ["Großbritannien", "Zypern", "Hongkong"],
  popularAssets: "Actifs populaires",
  worksDay1: "Lun-Ven",
  worksDay2: "Sam-Dim",
  mail: "Mail",
  works: "Heures d'ouverture",
  ourAddress: "Notre adresse",
  client: "Accord client",
  legalInfo: "Accord client",
};

export default fr;
