const de = {
  btn: {
    registration: "Anmeldung",
    openAccount: "Offener Account",
    join: "Verbinden",
    login: "Betreten",
    register: "Registrieren",
    continue: "Weitermachen",
    begin: "Beginnen",
    createAccount: "Ein Konto erstellen",
  },
  menu: {
    home: "Heim",
    markets: "Märkte",
    about: "Über uns",
    contacts: "Kontakte",
    platform: "Plattform",
    faq: "FAQ",
    trade: "Handel",
    strategies: "Strategien",
    tools: "Werkzeuge",
  },
  subMenu: {
    crypto: "Grundlagen der Kryptowährung",
    security: "Sicherheit und Schutz",
  },
  form: {
    name: "Name",
    phone: "Telefonnummer",
    mail: "Deine E-Mail",
    lastName: "Nachname",
    password: "Passwort",
  },
  privacy: "Datenschutzrichtlinie",
  assets: "Vermögenswerte",
  support: "Unterstützung",
  leverageTo: "Schulter hoch",
  depositFrom: "Einzahlung ab",
  cities: ["Großbritannien", "Zypern", "Hongkong"],
  popularAssets: "Beliebte Vermögenswerte",
  worksDay1: "Mo-Fr",
  worksDay2: "Sa-So",
  mail: "Post",
  works: "Arbeitszeit",
  ourAddress: "Unsere Adresse",
  client: "Kundenvereinbarung",
  legalInfo: "Kundenvereinbarung",
};

export default de;
